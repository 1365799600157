<template>
<v-container style="padding: 0px;">
  <!-- <div>此处为 upload 上传文件内容设定</div> -->
  <div style="display:none;"  @click="_handleFileInputClick">
      <span>
        <v-icon class="btn_2-icons">mdi-paperclip</v-icon>
        <span>附件</span>
      </span>
      <input type="file" ref="fileIntercom" style="display:none;" @change="setFileForIntercom" />
  </div>

  <v-row class="pr_10 pl_10 w100 m_0" style="border: 1px solid #a9b8bf; border-bottom: 0px; ">
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            <v-icon small>mdi-format-bold</v-icon>
          </button>
        </template>
        <span>粗体</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <button v-bind="attrs" v-on="on" class="mr-2" @click="openColor">
            <v-icon small>mdi-format-color-text</v-icon>
          </button>
        </template>
        <span>文字颜色</span>
      </v-tooltip>
      <input ref="colorSelector" style="display:none;" type="color" @input="editor.chain().focus().setColor($event.target.value).run()" :value="editor.getAttributes('textStyle').color">
      <v-tooltip bottom v-if="!isShow">
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="addImage">
            <v-icon small>mdi-image-outline</v-icon>
          </button>
          <!-- <v-file-input hide-input small v-bind="attrs" v-on="on" class="mr_5"  @click="addImage">
            <v-icon small>mdi-image-outline</v-icon>
          </v-file-input> -->

        </template>
        <span>图片</span>
      </v-tooltip>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
            <v-icon small>mdi-format-quote-open</v-icon>
          </button>
        </template>
        <span>引用</span>
      </v-tooltip> -->

      <!-- font family -->
      <v-menu offset-y>
        <template v-slot:activator="{on: onMenu}">
          <v-tooltip bottom>
            <template v-slot:activator="{on: onTooltip}">
              <v-icon v-on="{ ...onMenu, ...onTooltip }" small>mdi-format-font</v-icon>
            </template>
            <span>字体</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item>
            <button @click="editor.chain().focus().setFontFamily('Inter').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'Inter' }) }">
              Inter
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'Comic Sans MS, Comic Sans' }) }">
              Comic Sans
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().setFontFamily('serif').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'serif' }) }">
              serif
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().setFontFamily('monospace').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'monospace' }) }">
              monospace
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().setFontFamily('cursive').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'cursive' }) }">
              cursive
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().unsetFontFamily().run()">
              Remove font-family
            </button>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- font size -->
      <!-- <v-menu offset-y>
        <template v-slot:activator="{attrs, on}">
          <v-icon class="ml-2" v-bind="attrs" v-on="on" small>mdi-format-size</v-icon>
        </template>
        <v-list>
          <v-list-item>
            <button @click="editor.chain().focus().setFontSize('12px').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontSize: '12px' }) }">
              12px
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="setFontSize('14px')" :class="{ 'is-active': editor.isActive('textStyle', { fontSize: '14px' }) }">
              14px
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().setFontFamily('serif').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'serif' }) }">
              serif
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().setFontFamily('monospace').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'monospace' }) }">
              monospace
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().setFontFamily('cursive').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'cursive' }) }">
              cursive
            </button>
          </v-list-item>
          <v-list-item>
            <button @click="editor.chain().focus().unsetFontFamily().run()">
              Remove font-family
            </button>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-divider class="ml-2" vertical></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5 ml-2" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
            <v-icon class="textEditorIcon">mdi-format-list-bulleted-square</v-icon>
          </button>
        </template>
        <span>无序列表</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
            <v-icon class="textEditorIcon">mdi-order-numeric-ascending</v-icon>
          </button>
        </template>
        <span>有序列表</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().setHorizontalRule().run()">
            <v-icon class="textEditorIcon">mdi-arrow-split-horizontal</v-icon>
          </button>
        </template>
        <span>水平的</span>
      </v-tooltip>
      <v-divider vertical></v-divider>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
            <v-icon class="textEditorIcon">mdi-format-italic</v-icon>
          </button>
        </template>
        <span>斜体</span>
      </v-tooltip> -->

      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
            <v-icon class="textEditorIcon">mdi-format-strikethrough</v-icon>
          </button>
        </template>
        <span>删除线</span>
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
            <v-icon class="textEditorIcon">mdi-code-json</v-icon>
          </button>
        </template>
        <span>代码</span>
      </v-tooltip> -->

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
            <span class="textEditorSpan">h1</span>
          </button>
        </template>
        <span>标题1</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
            <span class="textEditorSpan">h2</span>
          </button>
        </template>
        <span>标题2</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
            <span class="textEditorSpan">h3</span>
          </button>
        </template>
        <span>标题3</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
            <span class="textEditorSpan">h4</span>
          </button>
        </template>
        <span>标题4</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
            <span class="textEditorSpan">h5</span>
          </button>
        </template>
        <span>标题5</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <button v-bind="attrs" v-on="on" class="mr_5" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
            <span class="textEditorSpan">h6</span>
          </button>
        </template>
        <span>标题6</span>
      </v-tooltip> -->

      <!-- <button class="mr_5" @click="moreEditOptions = !moreEditOptions">
        <span class="textEditorSpan">更多</span>
      </button> -->

    </div>
  </v-row>
  <v-row class="w100 m_0">
    <v-col cols=12 class="p_0">
      <editor-content class="content" :editor="editor" />
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import {
  Editor,
  EditorContent
} from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Blockquote from '@tiptap/extension-blockquote';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Placeholder from '@tiptap/extension-placeholder';
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'
import {
  Color
} from '@tiptap/extension-color'

//new
import {
  mapGetters,
  mapActions
} from "vuex";

export default {
  //props: ['content'],
  props: ['content','isShow'],

  data() {
    return {
      editor: null,
      prgValue: '',
      enableSubmitBtn: false,
      moreEditOptions: false,

      //new
      file_downUri:'',
      file_uri:"",

      //isShow:true,
    }
  },
  components: {
    EditorContent,
  },
  watch: {
    content: function (val) {
      this.editor.commands.setContent(val);
    }
  },
  created() {
    this.editor = new Editor({
      content: `<span style="color: #958DF1; font-family Inter"></span>${this.content == undefined ? '' : this.content}`,
      extensions: [
        StarterKit,
        Text,
        TextStyle,
        FontFamily,
        Color,
        Image,
        Document,
        Paragraph,
        Blockquote,
        Placeholder.configure({
          placeholder: "请输入详情",
          // emptyNodeClass: 'is-empty',
          // emptyNodeText: this.$t('editor.placeholder'),
          // showOnlyWhenEditable: true
        }),
      ],
      onUpdate: () => {
        if (this.editor.getHTML() != "<p></p>") {
          this.enableSubmitBtn = true;
          this.prgValue = this.editor.getHTML();
          this.$emit('input', {
            enableSubmitBtn: this.enableSubmitBtn,
            prgValue: this.prgValue,
          });
        } else {
          this.prgValue = "";
          this.enableSubmitBtn = false;
          this.$emit("input", {
            enableSubmitBtn: this.enableSubmitBtn,
            prgValue: this.prgValue,
          });
        }
      }
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    //new start
    ...mapActions("intercom", [
      "getIntercom",
      "addIntercom",
      "deleteIntercom",
      "updateIntercom",
    ]),
    ...mapActions("fileUpload", [
      "initFiles",
      
      "fileUpload",
      "filesUpload",
      "deleteFileUploadById",
    ]),
    ...mapGetters("fileUpload", ["intercomFiles"]),
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
    
    async _handleFileInputClick() {
      this.$refs.fileIntercom.click();
    },

    async setFileForIntercom(e) {
      this.file = e.target.files[0];
      var formData = new FormData();
      formData.append("files", this.file);

      //debugger;
      this.filesUpload(formData).then(res => {
        this.setSettingCall({
          st_category_id: 100,
          st_category_type: 100,
          st_value: JSON.stringify(res[0])
        });
         
        //this.fileUrl = `${endpoints.FILE_DOWNLOAD}/${res[0].file_id}`
        //获取文件的 url
        this.file_downUri = res[0].file_downUri;
        console.log(res[0].file_downUri,'res[0].file_downUri')

        //开始执行图片插入编辑器 此处用的是同步方案解决，异步难度太大
      
        let _url = res[0].file_downUri;
        if(_url !== undefined){
            this.editor.chain().focus().setImage({
              src: _url
            }).run()
        } 

      });

             
      


    },

    //new end
    async addImage() {
     this._handleFileInputClick();

      //通过异步来实现 url 的获取，不过有 bug
      // setTimeout(() => {
      //   let _url = this.file_downUri;
      //   if(_url !== undefined){
      //     //const url = window.prompt('URL',_url);
      //     if (_url) {
      //       this.editor.chain().focus().setImage({
      //         src: _url
      //       }).run()
      //     }
      //   }        
      // }, 2000);

      
    },
    openColor() {
      this.$refs.colorSelector.click();
    },
    setFontSize(size) {
      
    }
  }
}
</script>

<style>
.content {
  box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  border-top: 1px solid #f1f1f1 !important;
  border: 1px solid #a9b8bf;
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: 0.625em;
  resize: vertical;
  background-color: white;
  height: 160px;
  overflow: auto;
}

.content .ProseMirror {
  outline: none;
  height: 100%;
}

.textEditorIcon {
  font-size: 1.1em !important;
  color: #83909a !important;
}

.mr_5 {
  margin-right: 5px;
  transition: all .5s ease;
  padding: 2px;
  font-size: 12px;
}

.mr_5:hover {
  background-color: #d9e0e5;
}

.m_0 {
  margin: 0px !important;
}

.p_0 {
  padding: 0px;
}

/* Placeholder (only at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}

/* Placeholder (on every new line) */
.ProseMirror p.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}
</style>
